.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 20px;
  max-width: 100%;
}
.contact p {
  text-align: center;
  margin: 0 10px;
  font-size: 1.4rem;
  line-height: 1.5;
  max-width: 100%;
}

.contact a {
  text-decoration: none;
  color: black;
}
.contact a:hover {
  color: blue;
}

.contactProperty {
  color: var(--greenAndyB);
}
