.privacy h3 {
  font-weight: bold;
  margin: 20px;
}

.privacy p {
  text-align: left;
  margin: 20px;
}

.privacy li {
  list-style-type: initial;
  line-height: 1.5;
}
.privacy .pageTitle {
  margin-bottom: 20px;
}
.privacy ul {
  margin: 20px;
}

.privacy strong {
  font-weight: bold;
}
