.reviewCard {
  width: 1000px;
  height: fit-content;
  margin: 60px auto;
  flex-shrink: 10;
}
.currentReview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: lightgrey;
  border: 2px solid black;
}
.reviewBank {
  visibility: hidden;
  overflow: hidden;
  height: 0;
}
.reviewTitle,
.reviewText {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  font-weight: 900;
  max-width: none;
}
.reviewTitle {
  font-size: 1.9rem;
  color: var(--goldAndyB);
  background-color: var(--blackAndyB);
  border-bottom: 2px solid black;
  padding: 20px;
}
.reviewText {
  font-size: 1.25rem;
  font-style: oblique;
  color: black;
  background-color: lightgrey;
  min-height: 350px;
  padding: 20px;
}
ul {
  list-style-type: none;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
}
.buttonContainer figure {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 900;
}
.buttonContainer button {
  width: 75px;
  height: 75px;
  border-radius: 30%;
  margin: 0 25px 10px 25px;
  padding-bottom: 10px;
  background-color: var(--goldAndyB);
  outline: none;
}
.buttonContainer button:hover {
  transform: scale(1.5);
}
.leftButtonArrow::before {
  content: "<<";
  font-size: 3rem;
}
.rightButtonArrow::after {
  content: ">>";
  font-size: 3rem;
}
.buttonContainer button:hover .leftButtonArrow {
  padding-right: 10px;
  transition-duration: 0.4s;
}
.buttonContainer button:hover .rightButtonArrow {
  padding-left: 10px;
  transition-duration: 0.4s;
}
.reviewBackgroundDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* background-image: url("./Resources/temporaryImages/reviewImage.jpeg"); */
  opacity: 0.7;
  background-repeat: no-repeat;
  background-position: 0% -50%;
  background-size: cover;
}
