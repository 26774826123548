.gallerySubheader {
  text-align: center;
  padding: 1rem;
  margin: auto;
}

/*#region ----- Slider Image ----- */

.sliderImageContainer {
  width: calc(100vw - 0.4rem);
  max-width: 800px;
  margin-top: 1.2rem;
  text-align: center;
  font-size: 1.5rem;
  background-color: hsl(50, 90%, 80%);
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 10px;
  user-select: none;
  border: 0.05rem solid grey;
}

.imageScrollerDiv {
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}
.comparison {
  display: grid;
  width: calc(100vw - 2.4rem);
  max-width: calc(800px - 2rem);
  height: calc(100vw - 2.4rem);
  max-height: calc(800px - 2rem);
}
#beforeImage {
  grid-column: 1;
  grid-row: 1;
  background-image: url(../Resources/Images/Gallery/before.jpg);
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100%;
  background-position: 90% 100%;
  z-index: 0;
}
#afterImage {
  background-image: url(../Resources/Images/Gallery/after.jpg);
  background-size: cover;
  background-position: 0% 100%;
  position: absolute;
  width: 50%;
  overflow: hidden;
  bottom: 0;
  height: 120%;
  border-right: 0.125rem solid black;
  transition-duration: 0.5s;
  z-index: 2;
}
#beforeImage p {
  position: absolute;
  font-size: 1.1rem;
  height: fit-content;
  transition-duration: 0.5s;
  color: white;
  background-color: rgba(0, 0, 0, 0.76);
  padding: 0.15rem 0.4rem;
  border-radius: 5px;
}
#beforeTag {
  right: 2%;
  top: 0.5rem;
  z-index: 1;
}
#afterTag {
  left: 2%;
  top: 0.5rem;
  z-index: 3;
  transition-delay: 0.5s;
}
#slider {
  grid-column: 1;
  grid-row: 1;
  z-index: 4;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  appearance: none;
}
#slider::-webkit-slider-thumb {
  visibility: hidden;
}
#slider::-ms-thumb {
  visibility: hidden;
}

#slider::-moz-range-thumb {
  visibility: hidden;
}

.sliderImageCaption {
  margin: 1.5rem;
  font-size: 1.4rem;
}

@media only screen and (max-width: 600px) {
  .sliderImageCaption {
    font-size: 1.2rem;
  }
}
input[type="range"] {
  position: relative;
  background-color: rgba(255, 255, 255, 0.972);
  width: 102%;
  outline: none;
}

/*#endregion ----- Slider Image ----- */

/*#region ----- Galley Grid ----- */
.galleryCardContainer {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
}
.galleryCard {
  width: 350px;
  height: 430px;
  background-color: hsl(50, 90%, 80%);
  padding: 1rem;
  margin: 14px;
  border-radius: 10px;
  user-select: none;
  border: 0.05rem solid grey;
}
@media only screen and (max-width: 382px) {
  .galleryCard {
    width: 100%;
    margin: 16px 0;
  }
}
.galleryCard img {
  width: 100%;
  height: 75%;
}
.galleryCard figcaption {
  text-align: center;
  font-size: 1.2rem;
}
.galleryCard:hover {
  transform: scale(1.5);
  transition-duration: 1s;
  border: 0.05rem solid black;
}

@media only screen and (max-width: 540px) {
  .galleryCard:hover {
    transform: scale(1);
    transition-duration: 1s;
    border: 0.05rem solid black;
  }
}
/*#endregion ----- Gallery Grid ----- */
