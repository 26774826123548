/*#region General Formatting */
:root {
  --greyAndyB: #747474;
  --greenAndyB: #009800;
  --goldAndyB: #ffd501;
  --blackAndyB: #272727;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-weight: normal;
  line-height: 1.2;
  user-select: none;
}
#root {
  height: fit-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
}
main {
  display: flex;
  flex-direction: column;
  width: 100%;
}
main section {
  margin: auto;
}
p {
  font-size: 1rem;
  text-align: center;
  line-height: 1.5;
  margin: 1.25rem auto;
  max-width: 900px;
}
img {
  height: auto;
  object-fit: cover;
}
a {
  cursor: pointer;
  font-weight: 500;
}
.pageContent {
  flex-grow: 1;
}
.pageTitle,
.subheading {
  word-spacing: 0.4em;
  font-size: 2.25rem;
  letter-spacing: 0.125rem;
  word-spacing: 0.09rem;
  background-color: var(--blackAndyB);
  color: var(--goldAndyB);
  margin: 0 auto;
  padding: 5px 0;
  text-align: center;
  width: 100%;
}
@media only screen and (max-width: 830px) {
  .pageTitle,
  .subheading {
    font-size: 1.5rem;
  }
}
.hamburgerButton,
.hamburgerCloseButton {
  display: none;
  outline: none;
}

@media only screen and (max-width: 830px) {
  .hamburgerButton,
  .hamburgerCloseButton {
    float: right;
    background-color: white;
    border: hidden;
    height: unset;
    user-select: none;
  }
  .hamburgerButton {
    display: block;
  }
  .hamburgerActive .hamburgerButton {
    display: none;
  }
  .hamburgerActive .hamburgerCloseButton {
    display: block;
    height: auto;
  }
}
/*---------Header---------*/

.upperHeader {
  display: flex;
  height: 120px;
  width: 100%;
  max-width: 1050px;
  text-align: right;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  flex-shrink: 0;
  z-index: 10;
}

@media only screen and (max-width: 830px) {
  .upperHeader {
    display: grid;
    position: sticky;
    top: 0;
    grid-template-columns: 150px 1fr 150px;
    background-color: rgb(254, 255, 255);
    height: fit-content;
  }
}
@media only screen and (max-width: 600px) {
  .upperHeader {
    display: flex;
  }
}
.bannerContactContainer a {
  display: flex;
  color: initial;
  text-decoration: none;
  margin: 0 1.7rem;
}
@media only screen and (max-width: 830px) {
  .bannerContactContainer div {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .upperHeaderRight {
    width: 100%;
    margin: 10px;
  }
}
@media only screen and (max-width: 830px) {
  .bannerContactContainer {
    margin: auto;
    width: fit-content;
  }
}

@media only screen and (max-width: 830px) {
  .bannerContactContainer a {
    margin: 0 5vw;
    width: fit-content;
  }
  .upperHeader button {
    margin: 0 1.5rem 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .bannerContactContainer a {
    margin: 0 7vw;
  }
  .upperHeader button {
    margin: 0 1rem 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .bannerContactContainer {
    float: left;
  }
}

@media only screen and (max-width: 600px) {
  .bannerLogo {
    display: none;
  }
}

.bannerLogo {
  height: 110px;
  width: auto;
  top: 0.625rem;
  z-index: 200;
  border: none;
}
@media only screen and (max-width: 830px) {
  .bannerLogo {
    height: 75px;
  }
}
.bannerLogoContainer {
  text-align: left;
}
.logo {
  height: 135px;
  width: auto;
  top: 0.625rem;
  z-index: 200;
  border: none;
}
.logo:hover {
  opacity: 0.7;
}

.contactText {
  color: var(--blackAndyB);
  white-space: pre;
  cursor: pointer;
  transition-duration: 0.7s;
  margin: 0px 10px;
}
.contactText:hover {
  color: var(--greenAndyB);
  transition-duration: 0.3s;
}

/*Hamburger Menu*/
.container {
  text-align: right;
  width: 100%;
  margin-right: 40px;
}
.bannerContactContainer {
  display: flex;
}

.bannerContactContainer div {
  margin-right: 0.7rem;
}
.upperHeader h3 {
  color: var(--greenAndyB);
  font-weight: bold;
  margin-bottom: 0.1rem;
}
.upperHeader i {
  color: lightgray;
  transition-duration: 0.3s;
  font-size: 30px;
}

.upperHeader i::before {
  font-size: 50px;
}
.upperHeader i::after {
  font-size: 50px;
}
@media only screen and (max-width: 830px) {
  .upperHeader i::before {
    font-size: 35px;
  }
  .upperHeader i::after {
    font-size: 35px;
  }
}
@media only screen and (max-width: 600px) {
  .upperHeader i::before {
    font-size: 27px;
  }
  .upperHeader i::after {
    font-size: 27px;
  }
}

.upperHeader a:hover i,
.upperHeader button:hover i {
  color: var(--greenAndyB);
  transition-duration: 0.3s;
}

.fas.fa-bars,
.fas.fa-times {
  float: right;
}

/*---------Navbar---------*/
nav {
  position: sticky;
  display: flex;
  justify-content: center;
  top: 0;
  color: var(--blackAndyB);
  background-color: var(--goldAndyB);
  font-weight: 900;
  z-index: 5;
  width: 100%;
}

.navBar {
  height: fit-content;
  justify-content: center;
  display: flex;
  font-size: 1.2rem;
}
@media only screen and (max-width: 830px) {
  nav {
    background-color: #ffd501ec;
    position: fixed;
    top: 79px;
  }
  .navBar {
    flex-direction: column;
    width: 75%;
    text-align: center;
    font-size: 1.2rem;
    display: none;
  }
  .navbar a {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
@media only screen and (max-width: 600px) {
  nav {
    top: 50px;
  }
}
@media only screen and (max-width: 830px) {
  .navBar.hamburgerActive {
    display: block;
    position: relative;
    width: 100%;
  }
}

@media only screen and (max-width: 830px) {
  .hamburgerActive a {
    width: 100%;
    text-align: center;
  }
}

.navBar a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1rem;
  word-spacing: 0.2rem;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  padding: 5px 30px;
  transition-duration: 0.2s;
  color: black;
}
.navBar a:visited {
  color: black;
}
.navBar a:visited.active {
  color: white;
}
.navBar a:hover {
  transition-duration: 0.5s;
  color: white;
  background-color: var(--greenAndyB);
}
@media only screen and (max-width: 830px) {
  .navBar a {
    margin: 5px 0;
    font-weight: bold;
  }
}
.active {
  background-color: var(--greenAndyB);
  color: white;
}
li {
  list-style-type: none;
}

@media only screen and (max-width: 830px) {
  #navBar > :last-child {
    border-bottom: 1px solid black;
  }
}

/*---------Footer-------*/
.fullFooter {
  position: sticky;
  bottom: -2rem;
  z-index: 5;
}
@media only screen and (orientation: landscape) and (max-height: 800px) {
  .fullFooter {
    position: relative;
  }
}
.socialMediaLinks {
  display: flex;
  justify-content: center;
  background-color: var(--blackAndyB);
}
.fab {
  margin: 5px;
  padding: 5px;
  border-radius: 50%;
}
.fab:hover {
  opacity: 0.6;
  background-color: white;
}
.fa-facebook,
.fa-facebook-messenger {
  color: #3b5998;
}
.fa-twitter {
  color: #00aced;
}
.fa-instagram {
  color: #517fa4;
}
.fa-spotify {
  color: #1ed760;
}
.fa-whatsapp {
  color: #1ed760;
}
.fa-skype {
  color: #00aff0;
}
.footerCentre {
  margin: auto;
  bottom: 0;
}
.PolicyContainer {
  height: 2rem;
  font-size: 1rem;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  width: 100%;
}
.copyrightText {
  margin: 0px 0.5rem;
}
