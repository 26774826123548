.about {
  display: flex;
  flex-direction: column;
}
.about section {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  width: 100%;
  margin: auto;
  justify-content: center;
}
.about p {
  margin: 20px;
}
#andyImage {
  width: 95vw;
  height: 95vw;
  max-width: 500px;
  max-height: 500px;
  margin: 20px auto;
}
#terryImage {
  width: 95vw;
  height: 53vw;
  max-width: 600px;
  max-height: 337px;
  margin: 20px auto;
}
