.blog {
  display: flex;
  flex-direction: column;
}
.blog section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 900px;
  margin: auto;
  justify-content: center;
}
.blogImage {
  width: 90%;
  max-width: 600px;
  margin: 20px auto;
  border-radius: 10px;
}

.blog p {
  margin: 20px;
}

.comingSoon {
  font-weight: bold;
}
