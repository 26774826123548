.serviceGrid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.serviceCard {
  position: relative;
  display: grid;
  justify-content: center;
  margin: 1rem;
  flex-shrink: 1;
  height: 300px;
  width: 400px;
  min-width: 225px;
  padding: 0.6rem;
  color: black;
  background-color: hsl(50, 90%, 80%);
  border-radius: 10px;
  border: 1px solid grey;
  user-select: none;
}
@media only screen and (max-width: 864px) {
  .serviceCard {
    width: 350px;
  }
}

.imageUnderlay {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0rem 1rem;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}

.hiddenServiceTitle {
  margin-top: 1rem;
  font-weight: bold;
}

@media only screen and (max-width: 400px) {
  .hiddenServiceTitle {
    display: none;
  }
}
.hiddenServiceText {
  font-weight: bolder;
  opacity: 0;
  transition-duration: 0.6s;
}
@media only screen and (max-width: 400px) {
  .hiddenServiceText {
    margin: auto;
  }
}
.imageOverlay {
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.serviceImage {
  position: relative;
  height: 100%;
  width: 100%;
  transition-duration: 0.6s;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}
.serviceTitle {
  position: absolute;
  bottom: 0.85rem;
  left: 0.85rem;
  background-color: rgba(255, 255, 255, 0.698);
  text-align: center;
  font-weight: bold;
  width: calc(100% - 1.7rem);
  padding: 5px;
}

.serviceCard:hover .serviceImage,
.serviceCard:hover .serviceTitle {
  opacity: 0.07;
  transition-duration: 0.6s;
}

.serviceCard:hover .imageUnderlay {
  opacity: 1;
  transition-duration: 0.6s;
  z-index: 2;
}
.serviceCard:hover .hiddenServiceText {
  opacity: 1;
  transition-duration: 0.6s;
}
