.homePage {
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  align-items: center;
}
@media only screen and (min-width: 830px) {
  .homePage p {
    font-size: 1.25rem;
  }
}

.homePage .pictureBanner {
  width: 100%;
  background-image: url("../Resources/Images/Home/bannerBeeCompressed.jpg");
  background-position: 100% 50%;
  background-size: 100vw auto;
  background-repeat: no-repeat;
  margin-top: 0;
  margin: auto;
  height: 17vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.pictureBanner p {
  font-size: 3rem;
  font-weight: 600;
  border-radius: 1rem;
  letter-spacing: 1px;
  text-align: center;
  font-style: italic;
  min-width: 585px;
  max-width: 800px;
  margin: 0;
  margin-left: 5%;
  color: black;
  text-shadow: 3px 3px 0px var(--goldAndyB);
}

@media only screen and (max-width: 1150px) {
  .pictureBanner p {
    font-size: 2.5rem;
    margin-left: 10px;
    min-width: 500px;
    max-width: 60%;
  }
}

@media only screen and (max-width: 830px) {
  .pictureBanner p {
    text-shadow: none;
    font-size: 1.8rem;
    min-width: 350px;
  }
}
@media only screen and (max-width: 600px) {
  .homePage .pictureBanner {
    height: 30vw;
  }
  .pictureBanner p {
    margin-left: 5px;
    min-width: 100px;
    font-size: 1.2rem;
  }
}
.homeBlurb {
  width: 100%;
  text-align: center;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.homeQuestions {
  margin: 0px auto;
  text-align: left;
  width: fit-content;
  max-width: 750px;
}
.homeQuestions p {
  text-align: left;
}

.homeQuoteText {
  background-color: var(--goldAndyB);
  max-width: 400px;
  border: 2px solid black;
  padding: 5px;
}

@media only screen and (max-width: 830px) {
  .homeQuoteText {
    max-width: 325px;
  }
}
.doYou {
  font-weight: bold;
  color: var(--greenAndyB);
}

.emphasisedAll {
  font-style: italic;
  font-weight: bold;
}
